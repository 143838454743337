export enum FilterType {
  filter = "filter",
  specific = "specific",
  all = "all",
}
export type Filter = {
  filterType: FilterType;
  restaurants: string;
} & (AllTimeFilter | SpecificFilter | FilterFilter);

type AllTimeFilter = {
  filterType: FilterType.all;
  allTime: boolean;
  customerFilterId?: string;
  dateFrom?: string;
  dateTo?: string;
};
type SpecificFilter = {
  filterType: FilterType.specific;
  allTime: boolean;
  customerIds: { [customerId: string]: boolean };
};
type FilterFilter = {
  filterType: FilterType.filter;
  allTime?: boolean;
  dateFrom?: string;
  dateTo?: string;
  customerFilterId: string;
};
